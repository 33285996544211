<script setup>
import { useForm } from '@inertiajs/vue3';

import Button from '@/Components/Button/Button.vue';
import Footer from '@/Components/Footer/FooterLogin.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { mapErrors } from '@/utilities';
import { inject, nextTick, ref } from 'vue';
let route = inject('route');

const recovery = ref(false);

const form = useForm({
    code: '',
    recovery_code: '',
});

const recoveryCodeInput = ref(null);
const codeInput = ref(null);

const toggleRecovery = async () => {
    recovery.value ^= true;

    await nextTick();

    if (recovery.value) {
        form.code = '';
    } else {
        form.recovery_code = '';
    }
};

const submit = () => {
    form.post(route('two-factor.login'), {
        onError: (errors) => (form.errors = mapErrors(errors, { code: 'recovery_code' })),
    });
};
</script>

<template>
    <GuestLayout title="Two-factor Confirmation">
        <div class="container flex flex-col items-center w-full relative -top-[200px]">
            <div class="w-full p-8 bg-white rounded-2xl max-w-[550px] text-blue">
                <h1 class="text-[32px] font-bold mb-2">{{ $t('Two Factor Authentication') }}</h1>
                <div class="mb-5 text-base">
                    <p v-if="!recovery">
                        {{
                            $t(
                                'Please confirm access to your account by entering the authentication code provided by your authenticator application.'
                            )
                        }}
                    </p>
                    <p v-else>
                        {{
                            $t(
                                'Please confirm access to your account by entering one of your emergency recovery codes.'
                            )
                        }}
                    </p>
                </div>

                <form @submit.prevent="submit">
                    <div v-if="!recovery">
                        <FormKit
                            type="text"
                            name="code"
                            ref="codeInput"
                            :label="$t('Code')"
                            v-model="form.code"
                            :errors="form.errors.code"
                            :value="form.code"
                        />
                    </div>

                    <div v-else>
                        <FormKit
                            id="recovery_code"
                            ref="recoveryCodeInput"
                            type="text"
                            name="code"
                            :label="$t('Code')"
                            v-model="form.recovery_code"
                            :errors="form.errors.recovery_code"
                            :value="form.recovery_code"
                        />
                    </div>

                    <div class="w-full mt-8 text-xs">
                        {{ $t('Don’t have access to your authenticator device ?') }}
                        <button
                            type="button"
                            v-if="!recovery"
                            @click.prevent="toggleRecovery"
                            class="inline-block font-bold transition-colors duration-300 hover:text-pink"
                        >
                            {{ $t('Login with a recovery code.') }}
                        </button>
                        <button
                            type="button"
                            @click.prevent="toggleRecovery"
                            class="inline-block font-bold transition-colors duration-300 hover:text-pink"
                            v-else
                        >
                            {{ $t('Use an authentication code') }}
                        </button>
                    </div>

                    <div class="flex items-center justify-end mt-4">
                        <Button full-width :processing="form.processing">{{ $t('Log in') }}</Button>
                    </div>
                </form>
            </div>

            <Footer />
        </div>
    </GuestLayout>
</template>
